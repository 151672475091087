import { DELIVERED_OPTIONS, tableFieldTypes, TABLE_INPUT_TYPES } from './constants';
import moment from 'moment';
import { formatPrice } from './services';

export interface Style {
  marginTop?: string;
  marginLeft?: string;
}

interface CardFieldSchema {
  inputType?: string;
  label?: ((param1: unknown, param2: unknown) => string) | string;
  name?: string;
  placeholder?: string;
  bold?: boolean;
  style?: Style;
  showMoreButton?: boolean;
  defaultValue?: string;
  render?: (param1: any, param2: any) => string;
  showDataFrom?: string;
  min?: number;
  isShowAsterisk?: boolean;
  skipInCart?: boolean;
  skipIfInput?: boolean;
  learnMoreButton?: boolean;
  alignToLeft?: boolean;
  labelInCart?: string;
  learnMore?: boolean;
  isEventDataWasModified?: string;
  disabled?: boolean;
  options?: any;
  isDisabled?: boolean;
  icon?: string;
  keyForReveringValue?: string;
  isShowInputNote?: boolean;
  inputNote?: string;
  hideIfEmpty?: boolean;
  checkPending?: boolean;
  isLink?: boolean;
  showIfFieldHasValue?: string;
  showIfValueEqualsTo?: number;
  isGooglePlaceInput?: boolean;
  placeLinkName?: string;
  placeValueName?: string;
  isAddress?: boolean;
  linkLabel?: string;
  website?: string;
  phoneNumber?: string;
  postalCode?: string;
  defaultFormat?: string;
}

interface CardInfo {
  [fieldName: string]: CardFieldSchema | boolean;
}

type ConfigArray = (CardFieldSchema | CardInfo)[];

interface AirportTransferConfig {
  transfer1: ConfigArray;
  transfer2: ConfigArray;
}

interface VegasLimoTransferConfig {
  transfer1: ConfigArray;
  transfer2: ConfigArray;
}

interface CardsConfig {
  Dinner: ConfigArray;
  Ticket: ConfigArray;
  'Gift Bundle': ConfigArray;
  Hotel: ConfigArray;
  Limo: ConfigArray;
  Transfer: ConfigArray;
  'Vegas Limo Transfer': VegasLimoTransferConfig;
  'Vegas Limo Transfer Arrival': ConfigArray;
  'Vegas Limo Transfer Departure': ConfigArray;
  'Airport Transfer': AirportTransferConfig;
  'Airport Transfer Arrival': ConfigArray;
  'Airport Transfer Departure': ConfigArray;
  'CA Gift Card': ConfigArray;
  Parking: ConfigArray;
  Tailgate: ConfigArray;
  'City Pass': ConfigArray;
  'Rideshare Pass': ConfigArray;
  Fanatics: ConfigArray;
  'Fanatics Credit': ConfigArray;
  GiftCard: ConfigArray;
  Memorabilia: ConfigArray;
  'MLB Card': ConfigArray;
  'NBA Card': ConfigArray;
  'NHL Card': ConfigArray;
  'Multi-day Gift Card': ConfigArray;
  Outsourced: ConfigArray;
}

const TRANSPORTATION_SERVICES_CONFIG: ConfigArray = [
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.datetime,
    label: 'Pick Up At',
    name: 'date',
    placeholder: 'Enter Pick Up Time',
    bold: true,
    style: { marginTop: '20px' },
    render: (date: Date) => {
      if (!new Date(date).getTime()) {
        return '-';
      }
      return moment(date).format('hh:mm a');
    },
  },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: '6px' },
    label: 'Pick Up Address',
    name: 'pickUpPlaceName',
    placeholder: 'Enter Pick Up Address',
    isShowAsterisk: true,
    isGooglePlaceInput: true,
    placeLinkName: 'pickUpPlaceLink',
    placeValueName: 'pickUpPlaceValue',
    linkLabel: 'pickUpPlaceName',
    showDataFrom: 'pickUpAddress',
    render: (googleAddress, defaultAddress) => {
      return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
    },
    isAddress: true,
  },
  // {
  //   style: { marginTop: '6px' },
  //   label: 'Pick Up Place Link',
  //   name: 'pickUpPlaceLink',
  //   defaultValue: '-',
  //   // hideIfEmpty: true,
  //   isLink: true,
  //   skipIfInput: true,
  // },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.datetime,
    style: { marginTop: '20px' },
    label: 'Pre-show Hospitality At',
    name: 'preShowHospitality',
    placeholder: 'Enter Pre-show hospitality',
    render: (date: Date) => {
      if (!new Date(date).getTime()) {
        return '-';
      }
      return moment(date).format('h:mm a');
    },
    isDisabled: true,
    isShowInputNote: true,
    inputNote: 'This field may be edited in Pre-show Hospitality card',
    skipIfInput: true,
    bold: true,
  },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: '6px' },
    label: 'Restaurant Name',
    name: 'restaurantName',
    placeholder: 'Enter Restaurant Venue',
    // isEventDataWasModified: 'isEventVenueWasModified',
    // keyForReveringValue: 'venue',
    isDisabled: true,
    isShowInputNote: true,
    inputNote: 'This field may be edited in Pre-show Hospitality card',
    skipIfInput: true,
    render: (restaurantName) => {
      return restaurantName ? restaurantName : '-';
    },
  },
  {
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: '6px' },
    label: 'Restaurant Address',
    name: 'restaurantPlaceName',
    placeholder: 'Enter Restaurant Address',
    defaultValue: '-',
    // isGooglePlaceInput: true,
    showDataFrom: 'restaurantAddress',
    placeLinkName: 'restaurantPlaceLink',
    placeValueName: 'restaurantPlaceValue',
    linkLabel: 'restaurantPlaceName',
    render: (googleAddress, defaultAddress) => {
      return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
    },
    isDisabled: true,
    isShowInputNote: true,
    inputNote: 'This field may be edited in Pre-show Hospitality card',
    skipIfInput: true,
    isAddress: true,
  },
  // {
  //   style: { marginTop: '6px' },
  //   label: 'Restaurant Link',
  //   name: 'restaurantPlaceLink',
  //   defaultValue: '-',
  //   hideIfEmpty: true,
  //   isLink: true,
  //   skipIfInput: true,
  // },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.datetime,
    style: { marginTop: '20px' },
    label: 'Event At',
    name: 'startTime',
    placeholder: 'Enter Start time of Event',
    render: (date: Date) => {
      if (!new Date(date).getTime()) {
        return '-';
      }
      return moment(date).format('h:mm a');
    },
    isDisabled: true,
    isShowInputNote: true,
    inputNote: 'This field may be edited in Event card',
    skipIfInput: true,
    bold: true,
  },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: '6px' },
    label: 'Event Venue Name',
    name: 'venue',
    placeholder: 'Enter Event Venue',
    // isEventDataWasModified: 'isEventVenueWasModified',
    // keyForReveringValue: 'venue',
    isDisabled: true,
    isShowInputNote: true,
    inputNote: 'This field may be edited in Event card',
    skipIfInput: true,
    render: (venueName) => {
      return venueName ? venueName : '-';
    },
  },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: '6px' },
    label: 'Event Venue Address',
    name: 'placeName',
    showDataFrom: 'venueAddress',
    placeholder: 'Enter Event Venue Address',
    placeLinkName: 'placeLink',
    placeValueName: 'placeValue',
    render: (googleAddress, defaultAddress) => {
      return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
    },
    // isEventDataWasModified: 'isEventVenueAddressWasModified',
    // keyForReveringValue: 'venueAddress',
    isDisabled: true,
    isShowInputNote: true,
    inputNote: 'This field may be edited in Event card',
    // isGooglePlaceInput: true,
    skipIfInput: true,
    // isAddress: true,
  },
  // {
  //   style: { marginTop: '6px' },
  //   label: 'Venue Link',
  //   name: 'placeLink',
  //   defaultValue: '-',
  //   // hideIfEmpty: true,
  //   isLink: true,
  //   skipIfInput: true,
  // },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.datetime,
    label: 'Drop Off At',
    name: 'dropOff',
    placeholder: 'Enter Drop Off Time',
    bold: true,
    style: { marginTop: '20px' },
    render: (date: Date) => {
      if (!new Date(date).getTime()) {
        return '-';
      }
      return moment(date).format('MMM D, h:mm a');
    },
  },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: '6px' },
    label: 'Drop Off Address',
    name: 'dropOffPlaceName',
    placeholder: 'Enter Drop Off Address',
    isShowAsterisk: true,
    isGooglePlaceInput: true,
    placeLinkName: 'dropOffPlaceLink',
    placeValueName: 'dropOffPlaceValue',
    linkLabel: 'dropOffPlaceName',
    showDataFrom: 'dropOffAddress',
    render: (googleAddress, defaultAddress) => {
      return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
    },
    isAddress: true,
  },
  // {
  //   style: { marginTop: '6px' },
  //   label: 'Drop Off Place Link',
  //   name: 'dropOffPlaceLink',
  //   defaultValue: '-',
  //   // hideIfEmpty: true,
  //   isLink: true,
  //   skipIfInput: true,
  // },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.text,
    label: 'Vendor Name',
    name: 'vendorName',
    placeholder: 'Enter Vendor Name',
    bold: true,
    style: { marginTop: '20px' },
    showMoreButton: true,
    // skipInCart: true,
  },
  {
    cartInfo: true,
    vendorPhone: {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.phoneNumber,
      style: { marginTop: '6px', marginLeft: '0.25rem' },
      label: 'Vendor Phone',
      name: 'vendorPhone',
      placeholder: 'Enter Vendor Phone',
      render: (phoneNumber) => {
        return phoneNumber ? phoneNumber : '';
      },
      // skipInCart: true,
    },
    confirmationNumber: {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px', marginLeft: '0.25rem' },
      label: 'Confirmation Number',
      name: 'confirmationNumber',
      placeholder: 'Enter Confirmation Number',
      // skipInCart: true,
    },
  },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: '6px' },
    label: 'Contact Name',
    name: 'contactName',
    placeholder: 'Enter Contact Name',
    isShowAsterisk: true,
    // skipInCart: true,
  },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: '6px' },
    label: 'Contact Number',
    name: 'contactNumber',
    placeholder: 'Enter Contact Number',
    isShowAsterisk: true,
    // skipInCart: true,
  },
  {
    defaultValue: '-',
    style: { marginTop: '6px' },
    label: 'Celebration',
    name: 'celebration',
    skipIfInput: true,
    isShowAsterisk: true,
    // skipInCart: true,
  },
  {
    defaultValue: '-',
    inputType: TABLE_INPUT_TYPES.textarea,
    label: 'Additional Notes',
    name: 'notes',
    placeholder: 'Enter Notes',
    style: { marginTop: '20px' },
    // skipInCart: true,
  },
  {
    defaultValue: '-',
    style: { marginTop: '20px' },
    render: () => {
      return '* Fields filled in by the recipient';
    },
    alignToLeft: true,
    skipIfInput: true,
  },
];

export const CARD_FORM_SCHEMA: CardsConfig = {
  Dinner: [
    {
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Restaurant Name',
      name: 'restaurantName',
      placeholder: 'Enter Restaurant Name',
      bold: true,
      style: { marginTop: '20px' },
      showMoreButton: true,
      defaultValue: '-',
      render: (restaurantName) => {
        return restaurantName ? restaurantName : '-';
      },
    },
    {
      cartInfo: true,
      streetAddress: {
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Restaurant Address',
        name: 'restaurantPlaceName',
        placeholder: 'Enter Restaurant Address',
        defaultValue: '-',
        isGooglePlaceInput: true,
        showDataFrom: 'restaurantAddress',
        placeLinkName: 'restaurantPlaceLink',
        placeValueName: 'restaurantPlaceValue',
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
      },
      placeLink: {
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Restaurant Link',
        name: 'restaurantPlaceLink',
        defaultValue: '-',
        hideIfEmpty: true,
        isLink: true,
        skipIfInput: true,
      },
      phoneNumber: {
        inputType: TABLE_INPUT_TYPES.phoneNumber,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Restaurant Phone Number',
        name: 'restaurantPhoneNumber',
        placeholder: 'Enter Restaurant Phone Number',
        defaultValue: '-',
        render: (restaurantPhoneNumber) => {
          return restaurantPhoneNumber ? restaurantPhoneNumber : '-';
        },
      },
    },
    {
      inputType: TABLE_INPUT_TYPES.number,
      min: 1,
      style: { marginTop: '6px' },
      label: (qty, showDefault) => {
        if (!showDefault) {
          return `Table For ${qty} for`;
        }

        return 'Table For';
      },
      name: 'qty',
      placeholder: 'Enter Quantity',
      showDataFrom: 'recipientName',
      render: (qty: string, recipientName: number) => {
        return `${recipientName}`;
      },
      defaultValue: '-',
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Recipient Name',
      name: 'recipientName',
      placeholder: 'Enter recipient name',
      skipInCart: true,
      defaultValue: '-',
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Alternative First Name',
      name: 'alternativeFirstName',
      placeholder: 'Enter Alternative First Name',
      defaultValue: '-',
      isShowAsterisk: true,
      hideIfEmpty: true,
      isStringOnly: true,
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Alternative Last Name',
      name: 'alternativeLastName',
      placeholder: 'Enter Alternative Last Name',
      defaultValue: '-',
      isShowAsterisk: true,
      hideIfEmpty: true,
      isStringOnly: true,
    },
    {
      inputType: TABLE_INPUT_TYPES.datetime,
      style: { marginTop: '6px' },
      label: 'Date',
      name: 'date',
      placeholder: 'Enter Date',
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('MM/DD/YYYY');
      },
      skipInCart: true,
      defaultValue: '-',
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Food & Beverage Credit',
      labelInCart: 'Food & Beverage Credit Up To',
      name: 'foodBeverageCredit',
      placeholder: 'Enter Food & Beverage Credit',
      render: (foodBeverageCredit: string) => {
        return formatPrice(foodBeverageCredit);
      },
      defaultValue: '-',
    },
    // {
    //   label: 'Learn More',
    //   style: { marginTop: '6px' },
    //   skipIfInput: true,
    //   learnMoreButton: true,
    // },
    // {
    //   style: { marginTop: '6px' },
    //   name: 'date',
    //   showDataFrom: 'foodBeverageCredit',
    //   render: (date: string, foodBeverageCredit: string) => {
    //     return `Enjoy your hospitality starting at ${
    //       new Date(date).getTime() ? moment(date).format('h:mm a') : '-'
    //     } with an open menu up to ${formatPrice(
    //       foodBeverageCredit,
    //     )} including tax and gratuity. Please be timely for your reservation. The restaurant will only hold your table for up to 15 minutes past your reservation time. If you are running late, please contact the restaurant directly.`;
    //   },
    //   skipIfInput: true,
    //   defaultValue: '-',
    // },
    {
      style: { marginTop: '6px' },
      name: 'description',
      inputType: TABLE_INPUT_TYPES.textarea,
      label: 'Description',
      // render: (date: string, foodBeverageCredit: string) => {
      //   return `Enjoy your hospitality starting at ${
      //     new Date(date).getTime() ? moment(date).format('h:mm a') : '-'
      //   } with an open menu up to ${formatPrice(
      //     foodBeverageCredit,
      //   )} including tax and gratuity. Please be timely for your reservation. The restaurant will only hold your table for up to 15 minutes past your reservation time. If you are running late, please contact the restaurant directly.`;
      // },
      // skipIfInput: true,
      defaultValue: '-',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
    {
      defaultValue: '-',
      style: { marginTop: '20px' },
      render: () => {
        return '* Fields filled in by the recipient';
      },
      alignToLeft: true,
      skipIfInput: true,
    },
  ],
  Ticket: [
    {
      inputType: TABLE_INPUT_TYPES.datetime,
      style: { marginTop: '6px' },
      label: 'Date',
      name: 'date',
      placeholder: 'Enter Date',
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('MMMM Do YYYY, h:mm a');
      },
      defaultValue: '-',
      skipInCart: true,
      isEventDataWasModified: 'isEventDateWasModified',
      keyForReveringValue: 'eventDate',
      isDisabled: true,
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Event',
      name: 'performerName',
      placeholder: 'Enter Performer Name',
      bold: true,
      style: { marginTop: '20px' },
      disabled: true,
      defaultValue: '-',
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Venue Name',
      labelInCart: 'Venue',
      name: 'venue',
      placeholder: 'Enter Venue Name',
      bold: true,
      style: { marginTop: '20px' },
      showMoreButton: true,
      defaultValue: '-',
      isEventDataWasModified: 'isEventVenueWasModified',
      keyForReveringValue: 'venue',
      render: (venueName) => {
        return venueName ? venueName : '-';
      },
    },
    {
      cartInfo: true,
      defaultDisclaimer: {
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Default Disclaimer',
        name: 'defaultDisclaimer',
        placeholder: 'Enter Default Disclaimer',
        defaultValue: '-',
      },
      venueAddress: {
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Venue Address',
        name: 'venueAddress',
        placeholder: 'Enter Venue Address',
        defaultValue: '-',
        isEventDataWasModified: 'isEventVenueAddressWasModified',
        keyForReveringValue: 'streetAddress1',
        isGooglePlaceInput: true,
        showDataFrom: 'venueAddress',
        placeLinkName: 'placeLink',
        placeValueName: 'placeValue',
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
      },
      placeLink: {
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Venue Link',
        name: 'placeLink',
        defaultValue: '-',
        hideIfEmpty: true,
        isLink: true,
        skipIfInput: true,
      },
      venueCity: {
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Venue City, State, ZIP',
        name: 'venueCity',
        placeholder: 'Enter Venue City, State, ZIP',
        defaultValue: '-',
        isEventDataWasModified: 'isEventCityWasModified',
        keyForReveringValue: 'venueCity',
        render: (venueCity) => {
          return venueCity ? venueCity : '-';
        },
      },
      venuePhoneNumber: {
        inputType: TABLE_INPUT_TYPES.phoneNumber,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Venue Phone Number',
        name: 'venuePhoneNumber',
        placeholder: 'Enter Venue Phone Number',
        defaultValue: '-',
      },
      website: {
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Venue Website',
        name: 'website',
        placeholder: 'Enter Venue Website',
        isLink: true,
        defaultValue: '-',
        render: (website) => {
          return website ? website : '';
        },
      },
    },
    {
      inputType: TABLE_INPUT_TYPES.date,
      style: { marginTop: '6px' },
      label: 'Expected Arrival',
      name: 'expectedArrival',
      placeholder: 'Enter Expected Arrival',
      defaultValue: '-',
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('MMMM Do YYYY');
      },
    },
    {
      inputType: TABLE_INPUT_TYPES.select,
      style: { marginTop: '6px' },
      label: 'Tickets',
      name: 'ticketType',
      placeholder: 'Select Tickets',
      options: [],
      defaultValue: '-',
      render: function (id: number) {
        const name = this.options?.find((item: any) => Number(item?.id) === id)?.name;
        return name || '-';
      }
    },
    {
      inputType: TABLE_INPUT_TYPES.checkbox,
      style: { marginTop: '6px' },
      label: 'Tickets Sent',
      name: 'ticketsSent',
      placeholder: '',
      defaultValue: '-',
      showIfFieldHasValue: 'ticketType',
    },
    {
      inputType: TABLE_INPUT_TYPES.date,
      style: { marginTop: '6px' },
      label: 'Tickets Sent Date',
      name: 'ticketsSentDate',
      placeholder: 'M-D-YYYY',
      defaultFormat: 'M-D-YYYY',
      showIfFieldHasValue: 'ticketsSent',
      defaultValue: '-',
      render: (date: any) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('M-D-YYYY');
      },
      // showIfValueEqualsTo: 'Mobile Transfer Link',
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Mobile transfer link',
      name: 'link',
      placeholder: 'Enter Link',
      skipInCart: true,
      showIfFieldHasValue: 'ticketType',
      showIfValueEqualsTo: 13,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
      alignToLeft: true,
      isTextContainsLink: true,
      isDisabled: true,
      render: (note) => {
        return note;
      },
    },
  ],
  'Gift Bundle': [
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      label: 'Description',
      name: 'description',
      placeholder: '',
      // bold: true,
      style: { marginTop: '20px' },
      // alignToLeft: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '20px' },
      label: 'Tracking Number',
      name: 'trackingNumber',
      placeholder: 'Enter Tracking Number',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
  Hotel: [
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Hotel Name',
      name: 'hotelName',
      placeholder: 'Enter Hotel Name',
      bold: true,
      style: { marginTop: '20px' },
      showMoreButton: true,
    },
    {
      cartInfo: true,
      hotelAddress: {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Hotel Address',
        name: 'placeName',
        placeholder: 'Enter Hotel Address',
        isGooglePlaceInput: true,
        placeLinkName: 'placeLink',
        placeValueName: 'placeValue',
        showDataFrom: 'hotelAddress',
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
      },
      placeLink: {
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Hotel Link',
        name: 'placeLink',
        defaultValue: '-',
        hideIfEmpty: true,
        isLink: true,
        skipIfInput: true,
      },
      hotelCity: {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Hotel City, State, ZIP',
        name: 'hotelCity',
        placeholder: 'Enter Hotel City, State, ZIP',
        render: (hotelCity) => {
          return hotelCity ? hotelCity : '-';
        },
      },
      hotelPhone: {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.phoneNumber,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Hotel Phone',
        name: 'hotelPhone',
        placeholder: 'Enter Hotel Phone',
        render: (phoneNumber) => {
          return phoneNumber ? phoneNumber : '';
        },
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.datetime,
      label: 'Check In Starting At',
      name: 'date',
      placeholder: 'Enter Check In Time',
      bold: true,
      style: { marginTop: '20px' },
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('hh:mm a');
      },
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.number,
      min: 1,
      style: { marginTop: '6px' },
      label: 'Number of Nights',
      labelInCart: 'Number of Nights, Rooms',
      name: 'numberOfNights',
      showDataFrom: 'roomsNumber',
      placeholder: 'Enter Number of Nights',
      render: (numberOfNights: number, numberOfRooms: number) => {
        return `${numberOfNights} ${numberOfNights > 1 ? 'Nights' : 'Night'}, ${numberOfRooms} ${
          numberOfRooms > 1 ? 'Rooms' : 'Room'
        }`;
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.number,
      min: 1,
      style: { marginTop: '6px' },
      label: 'Number of Rooms',
      name: 'roomsNumber',
      placeholder: 'Enter Number of Rooms',
      skipInCart: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Name Of Reservation',
      name: 'reservationName',
      placeholder: 'Enter Name Of Reservation',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.number,
      min: 1,
      style: { marginTop: '6px' },
      label: 'Number Of Occupants',
      name: 'occupantsNumber',
      placeholder: 'Enter Number Of Occupants',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Confirmation',
      name: 'confirmation',
      placeholder: 'Enter Confirmation',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.datetime,
      style: { marginTop: '6px' },
      label: 'Date',
      name: 'date',
      placeholder: 'Enter Date',
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('MM/DD/YYYY');
      },
      skipInCart: true,
    },
    // {
    //   defaultValue: '-',
    //   style: { marginTop: '6px' },
    //   label: 'Check-In Name',
    //   name: 'checkInName',
    //   skipIfInput: true,
    //   isShowAsterisk: true,
    // },
    {
      defaultValue: '-',
      style: { marginTop: '6px' },
      label: 'Check-In First Name',
      name: 'checkInFirstName',
      skipIfInput: true,
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      style: { marginTop: '6px' },
      label: 'Check-In Last Name',
      name: 'checkInLastName',
      skipIfInput: true,
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      style: { marginTop: '6px' },
      label: 'Contact Number',
      name: 'contactNumber',
      skipIfInput: true,
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      style: { marginTop: '6px' },
      label: 'Beds Number',
      name: 'bedsNumber',
      skipIfInput: true,
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      style: { marginTop: '6px' },
      label: 'Additional Requests',
      name: 'additionalRequests',
      skipIfInput: true,
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      style: { marginTop: '6px' },
      label: 'Celebration',
      name: 'celebration',
      skipIfInput: true,
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
    {
      defaultValue: '-',
      style: { marginTop: '20px' },
      render: () => {
        return '* Fields filled in by the recipient';
      },
      alignToLeft: true,
      skipIfInput: true,
    },
  ],
  Limo: TRANSPORTATION_SERVICES_CONFIG,
  Transfer: TRANSPORTATION_SERVICES_CONFIG,
  'Vegas Limo Transfer': {
    transfer1: [
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Vendor Name',
        name: 'vendorName1',
        placeholder: 'Enter Vendor Name',
        bold: true,
        style: { marginTop: '20px' },
        showMoreButton: true,
      },
      {
        cartInfo: true,
        vendorPhone: {
          defaultValue: '-',
          inputType: TABLE_INPUT_TYPES.phoneNumber,
          style: { marginTop: '6px', marginLeft: '0.25rem' },
          label: 'Vendor Phone',
          name: 'vendorPhone1',
          placeholder: 'Enter Vendor Phone',
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.datetime,
        label: 'Pick Up At',
        name: 'pickUp1',
        placeholder: 'Enter Pick Up Time',
        bold: true,
        style: { marginTop: '20px' },
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return '-';
          }
          return moment(date).format('MMM D, h:mm a');
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Name',
        name: 'pickUpName1',
        placeholder: 'Enter Pick Up Name',
        isShowAsterisk: true,
        linkLabel: 'pickUpName',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Address',
        name: 'pickUpAddress1',
        placeholder: 'Enter Pick Up Address',
        isGooglePlaceInput: true,
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        alignToLeft: true,
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Name',
        name: 'contactName1',
        placeholder: 'Enter Contact Name',
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Number',
        name: 'contactNumber1',
        placeholder: 'Enter Contact Number',
        isShowAsterisk: true,
      },
      {
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Transporting to',
        name: '',
        placeholder: '',
        bold: true,
        style: { marginTop: '20px' },
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Name',
        name: 'dropOffName1',
        placeholder: 'Enter Drop Off Name',
        linkLabel: 'dropOffName',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Address',
        name: 'dropOffAddress1',
        placeholder: 'Enter Drop Off Address',
        isGooglePlaceInput: true,
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        style: { marginTop: '6px' },
        label: 'Celebration',
        name: 'celebration1',
        skipIfInput: true,
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.textarea,
        style: { marginTop: '20px' },
        label: 'Additional Notes',
        name: 'notes1',
        placeholder: 'Enter Additional Notes',
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        render: () => {
          return '* Fields filled in by the recipient';
        },
        alignToLeft: true,
        skipIfInput: true,
      },
    ],
    transfer2: [
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Vendor Name',
        name: 'vendorName2',
        placeholder: 'Enter Vendor Name',
        bold: true,
        style: { marginTop: '20px' },
        showMoreButton: true,
      },
      {
        cartInfo: true,
        vendorPhone: {
          defaultValue: '-',
          inputType: TABLE_INPUT_TYPES.phoneNumber,
          style: { marginTop: '6px', marginLeft: '0.25rem' },
          label: 'Vendor Phone',
          name: 'vendorPhone2',
          placeholder: 'Enter Vendor Phone',
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.datetime,
        label: 'Pick Up At',
        name: 'pickUp2',
        placeholder: 'Enter Pick Up Time',
        bold: true,
        style: { marginTop: '20px' },
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return '-';
          }
          return moment(date).format('MMM D, h:mm a');
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Name',
        name: 'pickUpName2',
        placeholder: 'Enter Pick Up Name',
        linkLabel: 'pickUpName',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Address',
        name: 'pickUpAddress2',
        placeholder: 'Enter Pick Up Address',
        isGooglePlaceInput: true,
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        alignToLeft: true,
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Name',
        name: 'contactName2',
        placeholder: 'Enter Contact Name',
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Number',
        name: 'contactNumber2',
        placeholder: 'Enter Contact Number',
        isShowAsterisk: true,
      },
      {
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Transporting to',
        name: '',
        placeholder: '',
        bold: true,
        style: { marginTop: '20px' },
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Name',
        name: 'dropOffName2',
        placeholder: 'Enter Drop Off Name',
        linkLabel: 'dropOffName',
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Address',
        name: 'dropOffAddress2',
        placeholder: 'Enter Drop Off Address',
        isGooglePlaceInput: true,
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.textarea,
        style: { marginTop: '20px' },
        label: 'Additional Notes',
        name: 'notes2',
        placeholder: 'Enter Additional Notes',
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        render: () => {
          return '* Fields filled in by the recipient';
        },
        alignToLeft: true,
        skipIfInput: true,
      },
    ],
  },
  'Vegas Limo Transfer Arrival':
  [
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Vendor Name',
      name: 'vendorName',
      placeholder: 'Enter Vendor Name',
      bold: true,
      style: { marginTop: '20px' },
      showMoreButton: true,
    },
    {
      cartInfo: true,
      vendorPhone: {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.phoneNumber,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Vendor Phone',
        name: 'vendorPhone',
        placeholder: 'Enter Vendor Phone',
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.datetime,
      label: 'Pick Up At',
      name: 'pickUp',
      placeholder: 'Enter Pick Up Time',
      bold: true,
      style: { marginTop: '20px' },
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('MMM D, h:mm a');
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Pick Up Name',
      name: 'pickUpName',
      placeholder: 'Enter Pick Up Name',
      isShowAsterisk: true,
      linkLabel: 'pickUpName',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Pick Up Address',
      name: 'pickUpAddress',
      placeholder: 'Enter Pick Up Address',
      isGooglePlaceInput: true,
      placeLinkName: 'pickUpPlaceLink',
      placeValueName: 'pickUpPlaceValue',
      showDataFrom: 'pickUpAddress',
      render: (googleAddress, defaultAddress) => {
        return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
      },
      // isShowAsterisk: true,
      // isDisabled: false,
      // isAddress: true,
    },
    {
      defaultValue: '-',
      style: { marginTop: '20px' },
      alignToLeft: true,
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Contact Name',
      name: 'contactName',
      placeholder: 'Enter Contact Name',
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Contact Number',
      name: 'contactNumber',
      placeholder: 'Enter Contact Number',
      isShowAsterisk: true,
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Transporting to',
      name: '',
      placeholder: '',
      bold: true,
      style: { marginTop: '20px' },
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Drop Off Name',
      name: 'dropOffName',
      placeholder: 'Enter Drop Off Name',
      linkLabel: 'dropOffName',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Drop Off Address',
      name: 'dropOffAddress',
      placeholder: 'Enter Drop Off Address',
      isGooglePlaceInput: true,
      placeLinkName: 'dropOffPlaceLink',
      placeValueName: 'dropOffPlaceValue',
      showDataFrom: 'dropOffAddress',
      render: (googleAddress, defaultAddress) => {
        return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
      },
      // isShowAsterisk: true,
      // isDisabled: false,
      // isAddress: true,
    },
    {
      defaultValue: '-',
      style: { marginTop: '6px' },
      label: 'Celebration',
      name: 'celebration',
      skipIfInput: true,
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Restaurant Name',
      name: 'restaurantName',
      placeholder: 'Enter Restaurant Venue',
      // isEventDataWasModified: 'isEventVenueWasModified',
      // keyForReveringValue: 'venue',
      isDisabled: true,
      isShowInputNote: true,
      inputNote: 'This field may be edited in Pre-show Hospitality card',
      skipIfInput: true,
      render: (restaurantName) => {
        return restaurantName ? restaurantName : '-';
      },
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Restaurant Address',
      name: 'restaurantPlaceName',
      placeholder: 'Enter Restaurant Address',
      defaultValue: '-',
      // isGooglePlaceInput: true,
      showDataFrom: 'restaurantAddress',
      placeLinkName: 'restaurantPlaceLink',
      placeValueName: 'restaurantPlaceValue',
      linkLabel: 'restaurantPlaceName',
      render: (googleAddress, defaultAddress) => {
        return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
      },
      isDisabled: true,
      isShowInputNote: true,
      inputNote: 'This field may be edited in Pre-show Hospitality card',
      skipIfInput: true,
      isAddress: true,
    },
    {
      defaultValue: '-',
      style: { marginTop: '20px' },
      render: () => {
        return '* Fields filled in by the recipient';
      },
      alignToLeft: true,
      skipIfInput: true,
    },
  ],
  'Vegas Limo Transfer Departure': [
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Vendor Name',
      name: 'vendorName',
      placeholder: 'Enter Vendor Name',
      bold: true,
      style: { marginTop: '20px' },
      showMoreButton: true,
    },
    {
      cartInfo: true,
      vendorPhone: {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.phoneNumber,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Vendor Phone',
        name: 'vendorPhone',
        placeholder: 'Enter Vendor Phone',
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.datetime,
      label: 'Pick Up At',
      name: 'pickUp',
      placeholder: 'Enter Pick Up Time',
      bold: true,
      style: { marginTop: '20px' },
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('MMM D, h:mm a');
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Pick Up Name',
      name: 'pickUpName',
      placeholder: 'Enter Pick Up Name',
      linkLabel: 'pickUpName',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Pick Up Address',
      name: 'pickUpAddress',
      placeholder: 'Enter Pick Up Address',
      isGooglePlaceInput: true,
      placeLinkName: 'pickUpPlaceLink',
      placeValueName: 'pickUpPlaceValue',
      showDataFrom: 'pickUpAddress',
      render: (googleAddress, defaultAddress) => {
        return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
      },
      // isShowAsterisk: true,
      // isDisabled: false,
      // isAddress: true,
    },
    {
      defaultValue: '-',
      style: { marginTop: '20px' },
      alignToLeft: true,
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Contact Name',
      name: 'contactName',
      placeholder: 'Enter Contact Name',
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Contact Number',
      name: 'contactNumber',
      placeholder: 'Enter Contact Number',
      isShowAsterisk: true,
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Transporting to',
      name: '',
      placeholder: '',
      bold: true,
      style: { marginTop: '20px' },
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Drop Off Name',
      name: 'dropOffName',
      placeholder: 'Enter Drop Off Name',
      linkLabel: 'dropOffName',
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Drop Off Address',
      name: 'dropOffAddress',
      placeholder: 'Enter Drop Off Address',
      isGooglePlaceInput: true,
      placeLinkName: 'dropOffPlaceLink',
      placeValueName: 'dropOffPlaceValue',
      showDataFrom: 'dropOffAddress',
      render: (googleAddress, defaultAddress) => {
        return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
      },
      // isShowAsterisk: true,
      // isDisabled: false,
      // isAddress: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
    {
      defaultValue: '-',
      style: { marginTop: '20px' },
      render: () => {
        return '* Fields filled in by the recipient';
      },
      alignToLeft: true,
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.datetime,
      style: { marginTop: '20px' },
      label: 'Event At',
      name: 'startTime',
      placeholder: 'Enter Start time of Event',
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('h:mm a');
      },
      isDisabled: true,
      isShowInputNote: true,
      inputNote: 'This field may be edited in Event card',
      skipIfInput: true,
      bold: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Event Venue Name',
      name: 'venue',
      placeholder: 'Enter Event Venue',
      // isEventDataWasModified: 'isEventVenueWasModified',
      // keyForReveringValue: 'venue',
      isDisabled: true,
      isShowInputNote: true,
      inputNote: 'This field may be edited in Event card',
      skipIfInput: true,
      render: (venueName) => {
        return venueName ? venueName : '-';
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Event Venue Address',
      name: 'placeName',
      showDataFrom: 'venueAddress',
      placeholder: 'Enter Event Venue Address',
      placeLinkName: 'placeLink',
      placeValueName: 'placeValue',
      render: (googleAddress, defaultAddress) => {
        return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
      },
      // isEventDataWasModified: 'isEventVenueAddressWasModified',
      // keyForReveringValue: 'venueAddress',
      isDisabled: true,
      isShowInputNote: true,
      inputNote: 'This field may be edited in Event card',
      // isGooglePlaceInput: true,
      skipIfInput: true,
      // isAddress: true,
    },
  ],
  'Airport Transfer': {
    transfer1: [
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Vendor Name',
        name: 'vendorName1',
        placeholder: 'Enter Vendor Name',
        bold: true,
        style: { marginTop: '20px' },
        showMoreButton: true,
      },
      {
        cartInfo: true,
        vendorPhone: {
          defaultValue: '-',
          inputType: TABLE_INPUT_TYPES.phoneNumber,
          style: { marginTop: '6px', marginLeft: '0.25rem' },
          label: 'Vendor Phone',
          name: 'vendorPhone1',
          placeholder: 'Enter Vendor Phone',
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.datetime,
        label: 'Pick Up At',
        name: 'date1',
        placeholder: 'Enter Pick Up Time',
        bold: true,
        style: { marginTop: '20px' },
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return '-';
          }
          return moment(date).format('hh:mm a');
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Name',
        name: 'contactName1',
        placeholder: 'Enter Contact Name',
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Number',
        name: 'contactNumber1',
        placeholder: 'Enter Contact Number',
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Airport',
        name: 'airportName1',
        placeholder: 'Enter Airport Name',
        isShowAsterisk: true,
        render: (airportName) => {
          return airportName ? airportName : '';
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Confirmation',
        name: 'confirmation1',
        placeholder: 'Enter Confirmation Number',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: (flightNumber, showDefault) => {
          if (!showDefault) {
            return `Flight Number #${flightNumber} * Arriving`;
          }

          return 'Flight Number';
        },
        render: (flightNumber: string, date: string) => {
          if (!new Date(date).getTime()) {
            return '-';
          }

          return moment(date, 'YYYY-MM-DDThh:mm').format('hh:mm a');
        },
        showDataFrom: 'arrivalTime',
        name: 'flightNumber',
        placeholder: 'Enter Flight Number',
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.datetime,
        style: { marginTop: '6px' },
        label: 'Departure Flight Date',
        name: 'departureFlightDate',
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return '-';
          }
          return moment(date).format('MMMM Do YYYY');
        },
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.datetime,
        style: { marginTop: '6px' },
        label: 'Arrival Time',
        name: 'arrivalTime',
        render: (arrivalTime: string) => {
          if (!new Date(arrivalTime).getTime()) {
            return '-';
          }

          return moment(arrivalTime, 'YYYY-MM-DDTHH:mm A').format('hh:mm a');
        },
        placeholder: 'Enter Arrival Time',
        skipInCart: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Arrival Flight Airline',
        name: 'arrivalFlightAirline',
        skipIfInput: true,
        isShowAsterisk: true,
      },
      {
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Transporting to',
        name: '',
        placeholder: '',
        bold: true,
        style: { marginTop: '20px' },
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Hotel Name',
        name: 'hotelName1',
        placeholder: 'Enter Hotel Name',
        render: (hotelName) => {
          return hotelName ? hotelName : '';
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Hotel Address',
        name: 'hotelPlaceName',
        placeholder: 'Enter Hotel Address',
        isGooglePlaceInput: true,
        placeLinkName: 'hotelPlaceLink',
        placeValueName: 'hotelPlaceValue',
        showDataFrom: 'hotelAddress1',
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
      },
      {
        style: { marginTop: '6px' },
        label: 'Hotel Link',
        name: 'hotelPlaceLink',
        defaultValue: '-',
        hideIfEmpty: true,
        isLink: true,
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Address',
        name: 'dropOffPlaceName',
        placeholder: 'Enter Drop Off Address',
        isShowAsterisk: true,
        isGooglePlaceInput: true,
        placeLinkName: 'dropOffPlaceLink',
        placeValueName: 'dropOffPlaceValue',
        showDataFrom: 'dropOffAddress',
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
      },
      {
        style: { marginTop: '6px' },
        label: 'Drop Off Address Link',
        name: 'dropOffPlaceLink',
        defaultValue: '-',
        hideIfEmpty: true,
        isLink: true,
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.textarea,
        style: { marginTop: '20px' },
        label: 'Additional Notes',
        name: 'notes1',
        placeholder: 'Enter Additional Notes',
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        render: () => {
          return '* Fields filled in by the recipient';
        },
        alignToLeft: true,
        skipIfInput: true,
      },
    ],
    transfer2: [
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Vendor Name',
        name: 'vendorName2',
        placeholder: 'Enter Vendor Name',
        bold: true,
        style: { marginTop: '20px' },
        showMoreButton: true,
      },
      {
        cartInfo: true,
        vendorPhone: {
          defaultValue: '-',
          inputType: TABLE_INPUT_TYPES.text,
          style: { marginTop: '6px', marginLeft: '0.25rem' },
          label: 'Vendor Phone',
          name: 'vendorPhone2',
          placeholder: 'Enter Vendor Phone',
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.datetime,
        label: 'Pick Up At',
        name: 'date2',
        placeholder: 'Enter Pick Up Time',
        bold: true,
        style: { marginTop: '20px' },
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return '-';
          }
          return moment(date).format('hh:mm a');
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick-Up Address',
        name: 'pickUpPlaceName',
        isShowAsterisk: true,
        placeLinkName: 'pickUpPlaceLink',
        placeValueName: 'pickUpPlaceValue',
        isGooglePlaceInput: true,
        showDataFrom: 'pickUpAddress',
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
      },
      {
        style: { marginTop: '6px' },
        label: 'Pick Up Address Link',
        name: 'pickUpPlaceLink',
        defaultValue: '-',
        hideIfEmpty: true,
        isLink: true,
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Name',
        name: 'contactName2',
        placeholder: 'Enter Contact Name',
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Number',
        name: 'contactNumber2',
        placeholder: 'Enter Contact Number',
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Hotel Name',
        name: 'hotelName2',
        placeholder: 'Enter Hotel Name',
        render: (hotelName) => {
          return hotelName ? hotelName : '';
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Hotel Address',
        name: 'hotelPlaceName',
        placeholder: 'Enter Hotel Address',
        isGooglePlaceInput: true,
        placeLinkName: 'hotelPlaceLink',
        placeValueName: 'hotelPlaceValue',
        showDataFrom: 'hotelAddress2',
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
      },
      {
        style: { marginTop: '6px' },
        label: 'Hotel Link',
        name: 'hotelPlaceLink',
        defaultValue: '-',
        hideIfEmpty: true,
        isLink: true,
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Confirmation',
        name: 'confirmation2',
        placeholder: 'Enter Confirmation',
      },
      {
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Transporting to',
        name: '',
        placeholder: '',
        bold: true,
        style: { marginTop: '20px' },
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Airport',
        name: 'airportName2',
        placeholder: 'Enter Airport Name',
        isShowAsterisk: true,
        render: (airportName) => {
          return airportName ? airportName : '';
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Airport Address',
        name: 'airportPlaceName',
        placeholder: 'Enter Airport Address',
        isGooglePlaceInput: true,
        placeLinkName: 'airportPlaceLink',
        placeValueName: 'airportPlaceValue',
        showDataFrom: 'airportAddress',
        render: (googleAddress, defaultAddress) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
      },
      {
        style: { marginTop: '6px' },
        label: 'Airport Address Link',
        name: 'airportPlaceLink',
        defaultValue: '-',
        hideIfEmpty: true,
        isLink: true,
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Departure Flight Date',
        name: 'departureFlightDate',
        skipIfInput: true,
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return '-';
          }
          return moment(date).format('hh:mm a');
        },
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Departure Flight Airline',
        name: 'departureFlightAirline',
        skipIfInput: true,
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: (flightNumber, showDefault) => {
          if (!showDefault) {
            return `Flight Number #${flightNumber} * Departuring`;
          }

          return 'Flight Number';
        },
        render: (flightNumber: string, date: string) => {
          if (!new Date(date).getTime()) {
            return '-';
          }

          return moment(date, 'YYYY-MM-DDThh:mm').format('hh:mm a');
        },
        showDataFrom: 'departureFlightDate',
        name: 'departureFlightNumber',
        placeholder: 'Enter Flight Number',
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.textarea,
        style: { marginTop: '20px' },
        label: 'Additional Notes',
        name: 'notes2',
        placeholder: 'Enter Additional Notes',
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        render: () => {
          return '* Fields filled in by the recipient';
        },
        alignToLeft: true,
        skipIfInput: true,
      },
    ],
  },
  'Airport Transfer Arrival': [
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Vendor Name',
      name: 'vendorName',
      placeholder: 'Enter Vendor Name',
      bold: true,
      style: { marginTop: '20px' },
      showMoreButton: true,
    },
    {
      cartInfo: true,
      vendorPhone: {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.phoneNumber,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Vendor Phone',
        name: 'vendorPhone',
        placeholder: 'Enter Vendor Phone',
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.datetime,
      label: 'Pick Up At',
      name: 'date',
      placeholder: 'Enter Pick Up Time',
      bold: true,
      style: { marginTop: '20px' },
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('hh:mm a');
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Contact Name',
      name: 'contactName',
      placeholder: 'Enter Contact Name',
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Contact Number',
      name: 'contactNumber',
      placeholder: 'Enter Contact Number',
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Airport',
      name: 'airportName',
      placeholder: 'Enter Airport Name',
      isShowAsterisk: true,
      render: (airportName) => {
        return airportName ? airportName : '';
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Confirmation',
      name: 'confirmation',
      placeholder: 'Enter Confirmation Number',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: (flightNumber, showDefault) => {
        if (!showDefault) {
          return `Flight Number #${flightNumber} * Arriving`;
        }

        return 'Flight Number';
      },
      render: (flightNumber: string, date: string) => {
        if (!new Date(date).getTime()) {
          return '-';
        }

        return moment(date, 'YYYY-MM-DDThh:mm').format('hh:mm a');
      },
      showDataFrom: 'arrivalTime',
      name: 'flightNumber',
      placeholder: 'Enter Flight Number',
      isShowAsterisk: true,
    },
    // {
    //   defaultValue: '-',
    //   inputType: TABLE_INPUT_TYPES.datetime,
    //   style: { marginTop: '6px' },
    //   label: 'Departure Flight Date',
    //   name: 'departureFlightDate',
    //   render: (date: Date) => {
    //     if (!new Date(date).getTime()) {
    //       return '-';
    //     }
    //     return moment(date).format('MMMM Do YYYY');
    //   },
    //   isShowAsterisk: true,
    // },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.datetime,
      style: { marginTop: '6px' },
      label: 'Arrival Time',
      name: 'arrivalTime',
      render: (arrivalTime: string) => {
        if (!new Date(arrivalTime).getTime()) {
          return '-';
        }

        return moment(arrivalTime, 'YYYY-MM-DDTHH:mm A').format('hh:mm a');
      },
      placeholder: 'Enter Arrival Time',
      skipInCart: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Arrival Flight Airline',
      name: 'arrivalFlightAirline',
      skipIfInput: true,
      isShowAsterisk: true,
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Transporting to',
      name: '',
      placeholder: '',
      bold: true,
      style: { marginTop: '20px' },
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Hotel Name',
      name: 'hotelName',
      placeholder: 'Enter Hotel Name',
      render: (hotelName) => {
        return hotelName ? hotelName : '';
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Hotel Address',
      name: 'hotelPlaceName',
      placeholder: 'Enter Hotel Address',
      isGooglePlaceInput: true,
      placeLinkName: 'hotelPlaceLink',
      placeValueName: 'hotelPlaceValue',
      showDataFrom: 'hotelAddress',
      render: (googleAddress, defaultAddress) => {
        return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
      },
    },
    {
      style: { marginTop: '6px' },
      label: 'Hotel Link',
      name: 'hotelPlaceLink',
      defaultValue: '-',
      hideIfEmpty: true,
      isLink: true,
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Drop Off Address',
      name: 'dropOffPlaceName',
      placeholder: 'Enter Drop Off Address',
      isShowAsterisk: true,
      isGooglePlaceInput: true,
      placeLinkName: 'dropOffPlaceLink',
      placeValueName: 'dropOffPlaceValue',
      showDataFrom: 'dropOffAddress',
      render: (googleAddress, defaultAddress) => {
        return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
      },
    },
    {
      style: { marginTop: '6px' },
      label: 'Drop Off Address Link',
      name: 'dropOffPlaceLink',
      defaultValue: '-',
      hideIfEmpty: true,
      isLink: true,
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
    {
      defaultValue: '-',
      style: { marginTop: '20px' },
      render: () => {
        return '* Fields filled in by the recipient';
      },
      alignToLeft: true,
      skipIfInput: true,
    },
  ],
  'Airport Transfer Departure':
  [
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Vendor Name',
      name: 'vendorName',
      placeholder: 'Enter Vendor Name',
      bold: true,
      style: { marginTop: '20px' },
      showMoreButton: true,
    },
    {
      cartInfo: true,
      vendorPhone: {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Vendor Phone',
        name: 'vendorPhone',
        placeholder: 'Enter Vendor Phone',
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.datetime,
      label: 'Pick Up At',
      name: 'date',
      placeholder: 'Enter Pick Up Time',
      bold: true,
      style: { marginTop: '20px' },
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('hh:mm a');
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Pick-Up Address',
      name: 'pickUpPlaceName',
      isShowAsterisk: true,
      placeLinkName: 'pickUpPlaceLink',
      placeValueName: 'pickUpPlaceValue',
      isGooglePlaceInput: true,
      showDataFrom: 'pickUpAddress',
      render: (googleAddress, defaultAddress) => {
        return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
      },
    },
    {
      style: { marginTop: '6px' },
      label: 'Pick Up Address Link',
      name: 'pickUpPlaceLink',
      defaultValue: '-',
      hideIfEmpty: true,
      isLink: true,
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Contact Name',
      name: 'contactName',
      placeholder: 'Enter Contact Name',
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Contact Number',
      name: 'contactNumber',
      placeholder: 'Enter Contact Number',
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Hotel Name',
      name: 'hotelName',
      placeholder: 'Enter Hotel Name',
      render: (hotelName) => {
        return hotelName ? hotelName : '';
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Hotel Address',
      name: 'hotelPlaceName',
      placeholder: 'Enter Hotel Address',
      isGooglePlaceInput: true,
      placeLinkName: 'hotelPlaceLink',
      placeValueName: 'hotelPlaceValue',
      showDataFrom: 'hotelAddress',
      render: (googleAddress, defaultAddress) => {
        return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
      },
    },
    {
      style: { marginTop: '6px' },
      label: 'Hotel Link',
      name: 'hotelPlaceLink',
      defaultValue: '-',
      hideIfEmpty: true,
      isLink: true,
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Confirmation',
      name: 'confirmation',
      placeholder: 'Enter Confirmation',
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      label: 'Transporting to',
      name: '',
      placeholder: '',
      bold: true,
      style: { marginTop: '20px' },
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Airport',
      name: 'airportName',
      placeholder: 'Enter Airport Name',
      isShowAsterisk: true,
      render: (airportName) => {
        return airportName ? airportName : '';
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Airport Address',
      name: 'airportPlaceName',
      placeholder: 'Enter Airport Address',
      isGooglePlaceInput: true,
      placeLinkName: 'airportPlaceLink',
      placeValueName: 'airportPlaceValue',
      showDataFrom: 'airportAddress',
      render: (googleAddress, defaultAddress) => {
        return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
      },
    },
    {
      style: { marginTop: '6px' },
      label: 'Airport Address Link',
      name: 'airportPlaceLink',
      defaultValue: '-',
      hideIfEmpty: true,
      isLink: true,
      skipIfInput: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Departure Flight Date',
      name: 'departureFlightDate',
      skipIfInput: true,
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('hh:mm a');
      },
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Departure Flight Airline',
      name: 'departureFlightAirline',
      skipIfInput: true,
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: (flightNumber, showDefault) => {
        if (!showDefault) {
          return `Flight Number #${flightNumber} * Departuring`;
        }

        return 'Flight Number';
      },
      render: (flightNumber: string, date: string) => {
        if (!new Date(date).getTime()) {
          return '-';
        }

        return moment(date, 'YYYY-MM-DDThh:mm').format('hh:mm a');
      },
      showDataFrom: 'departureFlightDate',
      name: 'departureFlightNumber',
      placeholder: 'Enter Flight Number',
      isShowAsterisk: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
    {
      defaultValue: '-',
      style: { marginTop: '20px' },
      render: () => {
        return '* Fields filled in by the recipient';
      },
      alignToLeft: true,
      skipIfInput: true,
    },
  ],
  'CA Gift Card': [
    {
      style: { marginTop: '20px' },
      label: 'Description',
      inputType: TABLE_INPUT_TYPES.textarea,
      defaultValue: '-',
      icon: 'fa fa-lg fa-regular fa-notes',
      name: 'description',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
  Parking: [
    {
      inputType: TABLE_INPUT_TYPES.select,
      style: { marginTop: '20px' },
      bold: true,
      label: 'Parking Option',
      name: 'parkingOption',
      placeholder: 'Select Tickets',
      options: ['', 'Mobile', 'Not Mobile', 'ParkWhiz', 'License plate required'],
      // options: [{ id: '-', name: '-' }, 
      // { id: 'Mobile', name: 'Mobile' }, 
      // { id: 'Not Mobile', name: 'Not Mobile' }, 
      // { id: 'ParkWhiz', name: 'ParkWhiz' }, 
      // { id: 'License plate required', name: 'License plate required' }],
      defaultValue: '-',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Description',
      name: 'description',
      placeholder: 'Enter Description',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
  Tailgate: [
    {
      inputType: TABLE_INPUT_TYPES.select,
      style: { marginTop: '20px' },
      bold: true,
      label: 'Tailgate Option',
      name: 'ticketType',
      placeholder: 'Select Tickets',
      options: [],
      defaultValue: '-',
      render: function (id: number) {
        const name = this.options?.find((item: any) => Number(item?.id) === id)?.name;
        return name || '-';
      }
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Description',
      name: 'description',
      placeholder: 'Enter Description',
      isDisabled: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
    {
      inputType: TABLE_INPUT_TYPES.checkbox,
      style: { marginTop: '6px' },
      label: 'Tickets Sent',
      name: 'ticketsSent',
      placeholder: '',
      defaultValue: '-',
      showIfFieldHasValue: 'ticketType',
    },
    {
      inputType: TABLE_INPUT_TYPES.date,
      style: { marginTop: '6px' },
      label: 'Tickets Sent Date',
      name: 'ticketsSentDate',
      placeholder: 'M-D-YYYY',
      defaultFormat: 'M-D-YYYY',
      defaultValue: '-',
      showIfFieldHasValue: 'ticketsSent',
      render: (date: any) => {
        if (!new Date(date).getTime()) {
          return '-';
        }
        return moment(date).format('M-D-YYYY');
      },
    },
  ],
  'City Pass': [
    {
      style: { marginTop: '6px' },
      label: 'Description',
      inputType: TABLE_INPUT_TYPES.textarea,
      name: 'description',
      icon: 'fa fa-lg fa-regular fa-notes',
      // bold: true,
      isTextContainsLink: true,
    },
    {
      defaultValue: '-',
      style: { marginTop: '20px' },
      label: 'Attendees',
      bold: true,
      showMoreButton: true,
      name: 'attendeeNames',
      icon: 'fa fa-lg fa-regular  fa-user',
      hideIfEmpty: true,
      skipIfInput: true,
    },
    {
      cartInfo: true,
      checkPending: true,
      skipIfInput: true,
      attendeeName: {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.phoneNumber,
        style: { marginTop: '6px', marginLeft: '0.25rem' },
        label: 'Attendee Name',
        name: 'attendeeName',
        skipIfInput: true,
        checkPending: true,
        isShowAsterisk: true,
      },
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '20px' },
      label: 'Link',
      name: 'link',
      placeholder: 'Enter Link',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
    {
      style: { marginTop: '20px' },
      render: () => {
        return '* Fields filled in by the recipient';
      },
      alignToLeft: true,
      skipIfInput: true,
    },
  ],
  'Rideshare Pass': [
    {
      style: { marginTop: '6px' },
      label: 'Description',
      inputType: TABLE_INPUT_TYPES.textarea,
      name: 'description',
      icon: 'fa fa-lg fa-regular fa-notes',
      isTextContainsLink: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '20px' },
      label: 'Link',
      name: 'link',
      placeholder: 'Enter Link',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
  Fanatics: [
    {
      label: 'Description',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      name: 'description',
      // alignToLeft: true,
      // bold: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '20px' },
      label: 'Team/Event Type of Merch',
      name: 'merchDesc',
      placeholder: 'Enter team/event type of merch',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
  'Fanatics Credit': [
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'URL',
      name: 'eLink',
      placeholder: 'Enter URL',
      isLink: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'eGift code',
      name: 'eGiftCode',
      placeholder: 'Enter eGift code',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'PIN',
      name: 'PIN',
      placeholder: 'Enter PIN',
    },
    {
      style: { marginTop: '20px' },
      inputType: TABLE_INPUT_TYPES.textarea,
      label: 'Description',
      defaultValue: '-',
      // alignToLeft: true,
      name: 'description',
      icon: 'fa fa-lg fa-regular fa-notes',
      placeholder: 'Enter Description',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
      hideIfEmpty: true,
    },
  ],
  GiftCard: [
    {
      style: { marginTop: '20px' },
      label: 'Description',
      name: 'description',
      inputType: TABLE_INPUT_TYPES.textarea,
      defaultValue: '-',
      // alignToLeft: true,
      icon: 'fa fa-lg fa-regular fa-notes',
      // bold: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
  Memorabilia: [
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
  'MLB Card': [
    {
      style: { marginTop: '6px' },
      label: 'Description',
      inputType: TABLE_INPUT_TYPES.textarea,
      // skipIfInput: true,
      defaultValue: '-',
      // alignToLeft: true,
      icon: 'fa fa-lg fa-regular fa-notes',
      name: 'description',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'eGift code',
      name: 'eGiftCode',
      placeholder: 'Enter eGift code',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'PIN',
      name: 'PIN',
      placeholder: 'Enter PIN',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
  'NBA Card': [
    {
      style: { marginTop: '6px' },
      label: 'Description',
      inputType: TABLE_INPUT_TYPES.textarea,
      defaultValue: '-',
      icon: 'fa fa-lg fa-regular fa-notes',
      name: 'description',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'eGift code',
      name: 'eGiftCode',
      placeholder: 'Enter eGift code',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'PIN',
      name: 'PIN',
      placeholder: 'Enter PIN',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
  'NHL Card': [
    {
      style: { marginTop: '6px' },
      label: 'Description',
      inputType: TABLE_INPUT_TYPES.textarea,
      defaultValue: '-',
      // alignToLeft: true,
      icon: 'fa fa-lg fa-regular fa-notes',
      name: 'description',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'eGift code',
      name: 'eGiftCode',
      placeholder: 'Enter eGift code',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'PIN',
      name: 'PIN',
      placeholder: 'Enter PIN',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
  'Multi-day Gift Card': [
    {
      style: { marginTop: '6px' },
      label: 'Description',
      inputType: TABLE_INPUT_TYPES.textarea,
      defaultValue: '-',
      // alignToLeft: true,
      icon: 'fa fa-lg fa-regular fa-notes',
      name: 'description',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'eGift code',
      name: 'eGiftCode',
      placeholder: 'Enter eGift code',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'PIN',
      name: 'PIN',
      placeholder: 'Enter PIN',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
  Outsourced: [
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Description',
      name: 'description',
      placeholder: 'Enter Description',
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: '20px' },
      label: 'Additional Notes',
      name: 'notes',
      placeholder: 'Enter Additional Notes',
    },
  ],
};

export const CART_DETAILS_HEADER_CONFIG = {
  csr: {
    colTitle: 'CSR',
    type: tableFieldTypes.string,
  },
  budget: {
    colTitle: 'Budget',
    type: (item: any) => {
      return item.type === 'Management Fee' ? tableFieldTypes.percent : tableFieldTypes.price;
    },
  },
  cost: {
    colTitle: 'Cost',
    type: tableFieldTypes.price,
  },
  purchaseOrderId: {
    colTitle: 'PO',
    type: tableFieldTypes.number,
    render: (value: string) => {
      if (!value) {
        return 'Unassigned';
      }

      return value;
    },
  },
  delivered: {
    colTitle: 'Delivery Status',
    type: tableFieldTypes.string,
    render: (value: string) => {
      if (!value) {
        return 'Unassigned';
      }

      return DELIVERED_OPTIONS[value as keyof typeof DELIVERED_OPTIONS];
    },
  },
  description: {
    colTitle: 'Description',
    type: tableFieldTypes.string,
  },
} as const;
