import { useMutation } from '@apollo/client';
import { Spinner } from 'reactstrap';
import { infoMsg, successMsg } from '../../../../cache/vars';
import { GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD, GET_TICKETS_LIST_FOR_TICKETS_MODAL } from '../../../../queries/ticket';
import { EDIT_URL } from '../../../../queries/urls';
import { DELETE_URL } from '../../../../queries/vip-workflow';
import { CARTS_BY_FILTERS } from '../../../../utils/constants';
import qrCodeIcon from '../../../../assets/img/icons/common/qr-code-icon.svg';
import globeIcon from '../../../../assets/img/icons/common/globe-icon.svg';

const UploadedAdditionalUrls = ({
  ticketsData,
  setEditFileOrUrlInput,
  setTicketIdToEdit,
  ticketIdToEdit,
  editFileOrUrlInput,
  editFileNameRef,
  user
}: any) => {
  const parsedTicketsData = ticketsData.data?.getTicketsListForTicketsModal
    ? JSON.parse(ticketsData.data?.getTicketsListForTicketsModal)
    : {};

  let allTicketsArray: any[] = [];
  Object.values(CARTS_BY_FILTERS).forEach((typeArray) => {
    const typeKey = typeArray[0];
    if (parsedTicketsData[typeKey]) {
      allTicketsArray = [...allTicketsArray, ...parsedTicketsData[typeKey]];
    }
  });

  const [deleteUrl] = useMutation(DELETE_URL, {
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    onCompleted() {
      successMsg('Url Deleted');
    },
  });

  const [editUrl] = useMutation(EDIT_URL, {
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    fetchPolicy: 'no-cache',
    onCompleted() {
      const selectedTicket = allTicketsArray.find((ticket: { id: number; }) => ticket.id === ticketIdToEdit);
      
      if (editFileOrUrlInput === selectedTicket?.eTicketLink) {
        infoMsg('Url should be changed first in order to update');
      } else {
        successMsg('Url Updated');
      }
      setEditFileOrUrlInput('');
      setTicketIdToEdit(null);
    },
  });
  
  const hasUrls = ticketsData.data?.getTicketsListForTicketsModal &&
  Object.entries(JSON.parse(ticketsData.data?.getTicketsListForTicketsModal)).some(
    ([key, value]) => Array.isArray(value) && value.some(
      (item: any) => item.eTicketLink && item.deliveryStatus === 'UPLOADED'
    )
  );
  
  const truncateUrl = (url?: string) => {
    if (!url) return '';
  
    const words = url.split('');
  
    if (words.length > 40) {
      return words.slice(0, 40).join('') + '...';
    }
  
    return url;
  };
  
  if (ticketsData.loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="info" size="lg" />
      </div>
    );
  }
  
  if (!hasUrls) {
    return null;
  }

  return (
    <div style={{ border: '1px solid #E6EEF2', borderRadius: '10px' }} className="mt-4">
      {ticketsData.loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="info" size="lg" />
        </div>
      ) : (
        <div className="pl-4 pr-4 pb-3">
          {ticketsData.data?.getTicketsListForTicketsModal &&
            Object.entries(JSON.parse(ticketsData.data?.getTicketsListForTicketsModal)).map(
              ([key, value]: any, id) => {
                const urls = value.filter((item: any) => item.eTicketLink && item.deliveryStatus === 'UPLOADED');
                return urls.length > 0 && (
                  <div className="mt-3 ml-3 mr-3" key={id}>
                    <div>
                      <h2 className="text-capitalize">
                        {key === 'Event Details' ? 'Event Tickets' : key}
                      </h2>
                    </div>
                    <div>
                      {value.filter((item: any) => item.eTicketLink).map((ticket: any, idx: number) => {
                        if (key === 'Event Details' && ticket.deliveryStatus !== 'UPLOADED') {
                          return;
                        }
                        
                        if (!ticket.eTicketLink) {
                          return null;
                        }
  
                        return (
                          <div
                            className="d-flex align-items-center justify-content-between mb-2"
                            key={idx}
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              {ticket.isQrCode
                                ? <img className="mr-2" src={qrCodeIcon} alt="qr-code-icon" style={{ cursor: 'pointer' }}/>
                                : <img className="mr-2" src={globeIcon} alt="globe-icon" style={{ cursor: 'pointer' }}/>
                              }

                              {ticketIdToEdit && ticketIdToEdit === ticket.id ? (
                                <input
                                  type="text"
                                  style={{ width: '50%' }}
                                  value={editFileOrUrlInput}
                                  onChange={(e) => {
                                    setEditFileOrUrlInput(e.target.value);
                                  }}
                                  autoFocus
                                  ref={editFileNameRef}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      editUrl({
                                        variables: {
                                          fileId: ticket.id,
                                          title: key === 'Event Details' ? 'Event Tickets' : key,
                                          newUrl: editFileOrUrlInput,
                                          user: user
                                        },
                                      });
                                    }
                                  }}
                                />
                              ) : (
                                <span style={{ overflowWrap: 'anywhere' }}>
                                  {truncateUrl(ticket.eTicketLink)}
                                </span>
                              )}
                            </div>
                            <div className="d-flex justify-content-between">
                              {ticketIdToEdit && ticketIdToEdit === ticket.id ? (
                                <>
                                  <button
                                    className="btn btn-sm ml-2 mr-2 m-0 text-primary border-primary"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      editUrl({
                                        variables: {
                                          fileId: ticket.id,
                                          title: key === 'Event Details' ? 'Event Tickets' : key,
                                          newUrl: editFileOrUrlInput,
                                          user: user
                                        },
                                      });
                                    }}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="btn btn-sm mr-2 ml-2 m-0 text-primary border-primary"
                                    onClick={() => {
                                      setEditFileOrUrlInput('');
                                      setTicketIdToEdit(null);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <button
                                  className="btn btn-sm m-0 ml-2 mr-2 text-primary border-primary"
                                  onClick={() => {
                                    setEditFileOrUrlInput(ticket.eTicketLink);
                                    setTicketIdToEdit(ticket.id);
                                  }}
                                >
                                  Edit
                                </button>
                              )}
                              <button
                                className="btn btn-sm mr-2 m-0 text-primary border-primary"
                                onClick={(e) => {
                                  e.preventDefault();

                                  navigator.clipboard.writeText(ticket.eTicketLink);
                                  successMsg('Copied to clickboard');
                                }}
                              >
                                Copy URL
                              </button>
                              <button
                                style={{ border: 'none', background: 'none' }}
                                className="text-primary p-0"
                                onClick={() => {
                                  deleteUrl({
                                    variables: {
                                      fileId: ticket.id,
                                      title: key === 'Event Details' ? 'Event Tickets' : key,
                                      packageDetailsId: ticket.packageDetailsId,
                                      user: user
                                    },
                                  });
                                }}
                              >
                                <i className="fa fa-lg fa-thin fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              },
            )}
        </div>
      )}
    </div>
  );
};

export default UploadedAdditionalUrls;
